import React from "react"
import Link from "@/components/ani-link"
import ProjectPreviewBig from "@/components/projectPreview/big"
import cn from "classnames"
import * as style from "./index.modules.scss"

export default function IndexProjects({ projects }) {
  return (
    <div id="projects" className={"relative"}>
      <div className={"bg-light-gray"}>
        {projects.map((x) => (
          <ProjectPreviewBig key={x.slug} {...x} />
        ))}
      </div>

      <Link to={"/projects/"} className={style.projectsPlaceholder} />
      <div
        className={cn(
          style.projectsLink,
          "text-x9s desktop:text-x4s text-center flex items-center justify-center"
        )}
      >
        See all projects
      </div>
    </div>
  )
}
