import React, { useMemo } from "react"
import PropTypes from "prop-types"

export default function ContrastText({ backgroundColor, children, ...rest }) {
  const color = useMemo(() => overlayColor(backgroundColor), [backgroundColor])
  return (
    <div style={{ color, backgroundColor }} {...rest}>
      {children}
    </div>
  )
}

ContrastText.propTypes = {
  backgroundColor: PropTypes.string,
}

function overlayColor(color) {
  if (!/^#([\dA-Fa-f]{6}|[\dA-Fa-f]{3})$/.test(color)) {
    console.error("ContrastText: color is not in hex format", color)
    return "#000"
  }

  //if only first half of color is defined, repeat it
  if (color.length < 5) {
    color += color.slice(1)
  }
  return color.replace("#", "0x") > 0xffffff / 2 ? "#000" : "#fff"
}
