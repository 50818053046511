import React, { useEffect, useMemo } from "react"
import Seo from "@/components/seo"
import { graphql } from "gatsby"
import IndexProjects from "@/components/pages/index/projects"
import Anton from "@/components/anton"

const IndexPage = ({ data, transitionStatus, entry, exit }) => {
  const {
    projects,
    h1,
    allProjectsLinkText,
    scrollTriggerText,
    phrases,
  } = useMemo(() => {
    const page = data?.allContentfulPageHome?.edges?.[0]?.node

    return {
      projects: page.projectsList?.projects ?? [],
      h1: page.h1,
      allProjectsLinkText: page.allProjectsLinkText,
      scrollTriggerText: page.scrollTriggerText,
      phrases: page.phrases,
    }
  }, [data])

  return (
    <>
      <Seo title="Home" />
      <div className={`g-transition-status-${transitionStatus}`}>
        <Anton
          h1={h1}
          phrases={phrases}
          scrollTriggerText={scrollTriggerText}
        />

        <IndexProjects
          projects={projects}
          allProjectsLinkText={allProjectsLinkText}
        />
      </div>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allContentfulPageHome(limit: 1, filter: { node_locale: { eq: "en-US" } }) {
      edges {
        node {
          h1
          allProjectsLinkText
          scrollTriggerText
          phrases {
            text
            imageBounds {
              file {
                url
              }
            }
            image {
              file {
                url
              }
            }
          }
          projectsList {
            projects {
              title {
                title
              }
              slug
              shortDescription {
                shortDescription
              }
              shortDescriptionColor
              bigCover {
                fluid(maxWidth: 2000, quality: 70, toFormat: JPG) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
                fixed(height: 550, quality: 70, toFormat: JPG) {
                  ...GatsbyContentfulFixed_withWebp_noBase64
                }
                file {
                  details {
                    image {
                      height
                      width
                    }
                  }
                }
              }
              bigCoverAsABackground
              coverColor
            }
          }
        }
      }
    }
  }
`
